<template>
  <div class="invoice">
    <span class="title">{{$t('m.buy.invoice_information')}}</span>
    <div class="invoice_show" v-loading="loading">
      <el-descriptions
       v-for="(i,k) in invoiceList"
       v-show="k<2?true:showallgg"
        @click="cutinvoice(i.id,k)"
       :class="iscut==i.id?'iscut':''" :key="k">
        <el-descriptions-item :label="$t('m.buy.Invoice_type')+'：'">{{i.billType==0?$t('m.buy.plain_invoice'):$t('m.buy.vat_special_invoice')}}</el-descriptions-item>
        <el-descriptions-item :label="$t('m.buy.invoice_title')+'：'">{{i.billHeader}}</el-descriptions-item>
        <el-descriptions-item :label="$t('m.buy.check_taker')+'：'">{{i.billReceiverName}}</el-descriptions-item>
        <el-descriptions-item :label="$t('m.buy.Ticket_collection_telephone')+'：'">{{i.billReceiverPhone}}</el-descriptions-item>
        <el-descriptions-item :label="$t('m.buy.Receipt_address')+'：'">{{i.billReceiverAddress}}</el-descriptions-item>
        <el-descriptions-item v-if="showall" :label="$t('m.buy.Tariff_Item')+'：'">{{i.billCode}}</el-descriptions-item>
        <el-descriptions-item v-if="showall&&i.billType!=0" :label="$t('m.buy.deposit_bank')+'：'">{{i.billBank}}</el-descriptions-item>
        <el-descriptions-item v-if="showall&&i.billType!=0" :label="$t('m.buy.account_number')+'：'">{{i.billBankAccount}}</el-descriptions-item>
        <el-descriptions-item v-if="showall&&i.billType!=0" :label="$t('m.buy.Account_phone_number')+'：'">{{i.billPhone}}</el-descriptions-item>
        <el-descriptions-item v-if="showall&&i.billType!=0" :label="$t('m.buy.business_address')+'：'">{{i.billAddress}}</el-descriptions-item>
      </el-descriptions>
      <span class="showallsp"
      @click="this.showall=!showall"
      >{{showall?$t('m.buy.put_away'):$t('m.buy.unfold')}}
        <el-icon>
          <component :is="showall?'ArrowUpBold':'ArrowDownBold'"></component>
        </el-icon>
      </span>
      <div class="invoice_click">
        <el-button @click="adddialogVisible = true">{{$t('m.buy.Additional_invoice')}}</el-button>
        <el-button @click="changeaddress(),upDatadialogVisible = true">{{$t('m.buy.amend_an_invoice')}}</el-button>
        <el-button @click.stop="removedialogVisible = true">{{$t('m.buy.Deletion_of_invoice')}}</el-button>
      </div>
      <span class="show_all" v-if="invoiceList.length>=2"
      @click="this.showallgg=!showallgg">{{$t('m.buy.View_all')}}
        <el-icon>
          <component :is="showallgg?'ArrowUpBold':'ArrowDownBold'"></component>
        </el-icon>
      </span>
    </div>
     <!-- 新增发票 -->
    <el-dialog
      v-model="adddialogVisible"
      :destroy-on-close="true"
      width="40%"
      :title="$t('m.buy.Additional_invoice')"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Invoice_type')}}：</div>
          <div class="zyitem-right">
            <el-radio-group v-model="addindex" size="small"
            @change="changListNow">
              <el-radio-button label="0">{{$t('m.buy.plain_invoice')}}</el-radio-button>
              <el-radio-button label="1">{{$t('m.buy.vat_special_invoice')}}</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.invoice_title')}}：</div>
          <div class="zyitem-right">
            <el-input
              type="text"
              v-model="addinvoicemessage.billHeader"
              :placeholder="$t('m.buy.Please_enter_the_invoice_title')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.taxpayer_identification_number')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billCode"
              :placeholder="$t('m.buy.Please_enter_taxpayer_identification_number')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.check_taker')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billReceiverName"
              :placeholder="$t('m.buy.Please_enter_check_taker')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Ticket_collection_telephone')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billReceiverPhone"
              :placeholder="$t('m.buy.Please_enter_Ticket_collection_telephone')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Receipt_address')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billReceiverAddress"
              :placeholder="$t('m.buy.Please_enter_Receipt_address')"
            ></el-input>
          </div>
        </div>
        <!-- 增值税专用发票 -->
        <div v-show="addindex == 1">
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.deposit_bank')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billBank"
                :placeholder="$t('m.buy.Please_enter_deposit_bank')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.account_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billBankAccount"
                :placeholder="$t('m.buy.Please_enter_account_number')"
              ></el-input>
            </div>
          </div>

          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.Account_phone_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billPhone"
                :placeholder="$t('m.buy.Please_enter_Account_phone_number')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.business_address')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billAddress"
                :placeholder="$t('m.buy.Please_enter_business_address')"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="default-invoice">
          <div class="labels">{{$t('m.buy.Set_as_the_default_invoice')}}</div>
          <el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
            inline-prompt
            :active-text="$t('m.personalcenter_myorder.be')"
            :inactive-text="$t('m.personalcenter_myorder.not')"
          >
          </el-switch>
        </div>
        <el-button @click="addptsub" v-show="addindex == 0" class="save">
          {{$t('m.buy.Save_and_Use_plain')}}
        </el-button>
        <el-button @click="addptsub" v-show="addindex == 1" class="save">
          {{$t('m.buy.Save_and_Use')}}
        </el-button>
      </div>
    </el-dialog>
     <!-- 删除发票 -->
    <el-dialog
      v-model="removedialogVisible"
      :destroy-on-close="true"
      width="40%"
      :title="$t('m.buy.Deletion_of_invoice')"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <!-- <div class="title">删除发票</div> -->
        <div class="contremove"
         v-for="(i,k) in invoiceList" :key="k">
          <span class="span_show_left">{{$t('m.buy.Invoice_type')}}：{{i.billType==0?$t('m.buy.plain_invoice'):$t('m.buy.vat_special_invoice')}}</span>
          <span class="span_show_center">{{$t('m.buy.invoice_title')}}：{{i.billHeader}}</span>
          <el-button @click="deleteinvoice(i.id,k)">{{$t('m.buy.delete')}}</el-button>
        </div>
      </div>
    </el-dialog>
      <!-- 修改发票 -->
    <el-dialog
      v-model="upDatadialogVisible"
      :destroy-on-close="true"
      width="40%"
      :title="$t('m.buy.amend_an_invoice')"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <div class="fpitem">
          <div>{{$t('m.buy.Invoice_type')}}：{{upDatainvoicemessage.billType==0?$t('m.buy.plain_invoice'):$t('m.buy.vat_special_invoice')}}</div>
          <!-- <el-radio-group v-model="addindex" size="small"
            @change="changListNow">
              <el-radio-button label="0">普通发票</el-radio-button>
              <el-radio-button label="1">增值税专用发票</el-radio-button>
            </el-radio-group> -->
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.invoice_title')}}：</div>
          <div class="zyitem-right">
            <el-input
              type="text"
              v-model="upDatainvoicemessage.billHeader"
              :placeholder="$t('m.buy.Please_enter_the_invoice_title')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.taxpayer_identification_number')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billCode"
              :placeholder="$t('m.buy.Please_enter_taxpayer_identification_number')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.check_taker')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billReceiverName"
              :placeholder="$t('m.buy.Please_enter_check_taker')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Ticket_collection_telephone')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billReceiverPhone"
              :placeholder="$t('m.buy.Please_enter_Ticket_collection_telephone')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Receipt_address')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billReceiverAddress"
              :placeholder="$t('m.buy.Please_enter_Receipt_address')"
            ></el-input>
          </div>
        </div>
        <!-- 增值税专用发票 -->
        <div v-show="addindex == 1">
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.deposit_bank')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billBank"
                :placeholder="$t('m.buy.Please_enter_deposit_bank')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.account_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billBankAccount"
                :placeholder="$t('m.buy.Please_enter_account_number')"
              ></el-input>
            </div>
          </div>

          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.Account_phone_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billPhone"
                :placeholder="$t('m.buy.Please_enter_Account_phone_number')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.business_address')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billAddress"
                :placeholder="$t('m.buy.Please_enter_business_address')"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="default-invoice">
          <div class="labels">{{$t('m.buy.Set_as_the_default_invoice')}}</div>
          <el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
            inline-prompt
            :active-text="$t('m.personalcenter_myorder.be')"
            :inactive-text="$t('m.personalcenter_myorder.not')"
          >
          </el-switch>
        </div>
        <el-button @click="changeInvoice" v-show="addindex == 0" class="save">
          {{$t('m.buy.Save_and_Use_plain')}}
        </el-button>
        <el-button @click="changeInvoice" v-show="addindex == 1" class="save">
          {{$t('m.buy.Save_and_Use')}}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import emitter from '../../tools/bus'
import { getStore } from '../../tools/storage'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
// import { ArrowUpBold, ArrowDownBold } from '@element-plus/icons'

// 删除发票信息
const deleteinvoicefun = (id, data) => {
  return http.get(`mall-order/bill/delBill/${id}`, data)
}
// 修改发票信息
const updBill = (data) => {
  return http.post('/mall-order/bill/updBill', data)
}
export default {
  name: 'Invoice',
  data () {
    return {
      value: true,
      iscut: '',
      showallgg: ref(false), // 查看全部按钮
      invoiceList: reactive({}),
      adddialogVisible: false, // 新增发票弹窗开关
      removedialogVisible: false, // 删除发票开关
      upDatadialogVisible: false, // 修改发票开关
      list: [{ name: '普通发票' }, { name: '增值税专用发票' }],
      upDatainvoicemessage: reactive({}), // 修改发票数据

      loading: ref(true),

      changeindex: 0,
      addindex: 0,
      invoicevos: [],
      invoicemessage: '',
      changeinvoicemessage: '',
      invoicelist: [],
      addinvoicemessage: {
        billBank: '',
        billHeader: '',
        billCode: '',
        billBankAccount: '',
        billPhone: '',
        billAddress: '',
        billReceiverName: '',
        billReceiverPhone: '',
        billReceiverAddress: ''
      }
    }
  },
  components: {
  },
  methods: {
    cutinvoice (i, k) {
      this.changeindex = k
      // console.log(i + '---' + k)
      // console.log('切换发票')
      this.iscut = i
      emitter.emit('invoiceMsg', this.iscut)
    },
    changListNow (e) {
      console.log(e)
    },
    async addptsub () { // 新增发票
      let data = {}
      let status = 0
      // console.log(typeof this.addindex)
      if (this.addindex === 0) {
        const fptitle = this.addinvoicemessage.billHeader
        const spnumber = this.addinvoicemessage.billCode

        const billReceiverName = this.addinvoicemessage.billReceiverName
        const billReceiverPhone = this.addinvoicemessage.billReceiverPhone
        const billReceiverAddress = this.addinvoicemessage.billReceiverAddress
        if (fptitle === '' ||
         spnumber === '' ||
         billReceiverName === '' ||
         billReceiverPhone === '' ||
         billReceiverAddress === '') {
          ElMessage.error({
            message: '请完善相关信息'
          })
          console.log(this.addinvoicemessage)
          return
        }
        this.value ? (status = 1) : (status = 0)
        const usermessage = getStore('usermessage')
        const uid = JSON.parse(usermessage).id
        console.log(uid)
        data = {
          memberId: uid,
          billType: 0,
          billHeader: fptitle,
          billCode: spnumber,
          defaultStatus: status,
          billReceiverName: billReceiverName,
          billReceiverPhone: billReceiverPhone,
          billReceiverAddress: billReceiverAddress
        }
      } else {
        const fptitle = this.addinvoicemessage.billHeader
        const spnumber = this.addinvoicemessage.billCode
        this.value ? (status = 1) : (status = 0)
        const bank = this.addinvoicemessage.billBank
        const banknumber = this.addinvoicemessage.billBankAccount
        const bankphone = this.addinvoicemessage.billPhone
        const registeraddress = this.addinvoicemessage.billAddress
        const billReceiverName = this.addinvoicemessage.billReceiverName
        const billReceiverPhone = this.addinvoicemessage.billReceiverPhone
        const billReceiverAddress = this.addinvoicemessage.billReceiverAddress
        if (
          fptitle === '' ||
          spnumber === '' ||
          bank === '' ||
          banknumber === '' ||
          bankphone === '' ||
          registeraddress === '' ||
          billReceiverName === '' ||
          billReceiverPhone === '' ||
          billReceiverAddress === ''
        ) {
          ElMessage.error({
            message: '请完善相关信息'
          })
          console.log(this.addinvoicemessage)
          return
        }
        if (!/^1[345789]\d{9}$/.test(bankphone)) {
          ElMessage.warning({
            message: '手机号不正确'
          })
          return
        }
        const usermessage = getStore('usermessage')
        const uid = JSON.parse(usermessage).id
        data = {
          memberId: uid,
          billType: 1,
          billHeader: fptitle,
          billCode: spnumber,
          defaultStatus: status,
          billBank: bank,
          billBankAccount: banknumber,
          billPhone: bankphone,
          billAddress: registeraddress,
          billReceiverName: billReceiverName,
          billReceiverPhone: billReceiverPhone,
          billReceiverAddress: billReceiverAddress
        }
      }
      console.log(this.addinvoicemessage)

      this.adddialogVisible = false
      const loading = this.$loading({
        lock: true,
        text: '提交中'
      })

      try {
        http.post('mall-order/bill/addBill', data)
          .then(res => {
            console.log(res)
            loading.close()
            if (res.data.flag) {
              ElMessage.success({
                message: '添加成功',
                type: 'sucess'
              })
              this.invoicemessage = res.data.data
              this.$store.state.invoice = res.data.data
              this.addinvoicemessage = {
                billBank: '',
                billHeader: '',
                billCode: '',
                billBankAccount: '',
                billPhone: '',
                billAddress: '',
                billReceiverName: '',
                billReceiverPhone: '',
                billReceiverAddress: ''
              }
              console.log(this.addinvoicemessage)
              this.getlookinvoicelist()
            }
          }).catch(err => {
            loading.close()
            console.log(err)
          })
      } catch (e) {
        loading.close()
      }
    },
    async getlookinvoicelist () {
      this.loading = true
      http.get('mall-order/bill/getBillList')
        .then(res => {
          // console.log(res)
          if (res.data.code === 200) {
            this.invoiceList = res.data.data
            this.iscut = this.invoiceList[0].id
          } else {
            ElMessage.error({
              message: res.data.msg + '，请添加发票！'
            })
          }
          emitter.emit('invoiceMsg', this.iscut)
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
    },
    async deleteinvoice (id, index) { // 删除发票
      this.$confirm('您确定删除此发票吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const res = deleteinvoicefun(id, {})
        res.then(succ => {
          if (succ.data.code === 200) {
            ElMessage.success({ message: succ.data.msg })
            this.removedialogVisible = false
            this.invoiceList.splice(index, 1)
          }
        }).catch(err => {
          console.log(err)
        })
      })
        .catch(() => {})
    },
    changeaddress () { // 修改发票数据弹窗
      console.log(this.invoiceList)
      console.log(this.changeindex)

      this.addindex = this.invoiceList[this.changeindex].billType
      this.upDatainvoicemessage = this.invoiceList[this.changeindex]
    },
    async changeInvoice () { // 修改发票数据
      console.log(this.upDatainvoicemessage)
      let status = 0
      this.value ? (status = 1) : (status = 0)
      const data = {
        id: this.upDatainvoicemessage.id,
        billHeader: this.upDatainvoicemessage.billHeader,
        billBank: this.upDatainvoicemessage.billBank,
        billBankAccount: this.upDatainvoicemessage.billBankAccount,
        billPhone: this.upDatainvoicemessage.billPhone,
        billCode: this.upDatainvoicemessage.billCode,
        billContent: this.upDatainvoicemessage.billContent,
        billAddress: this.upDatainvoicemessage.billAddress,
        billType: this.upDatainvoicemessage.billType,
        billReceiverName: this.upDatainvoicemessage.billReceiverName,
        billReceiverPhone: this.upDatainvoicemessage.billReceiverPhone,
        billReceiverAddress: this.upDatainvoicemessage.billReceiverAddress,
        defaultStatus: status
      }
      const res = await updBill(data)
      console.log(res)
      if (res.data.code === 200) {
        this.getlookinvoicelist()
        this.upDatadialogVisible = false
        this.addindex = 0
        this.upDatainvoicemessage = {}
      }
    }
  },
  mounted () {
    // console.log(this.$props.invoicemsg)
    this.getlookinvoicelist()
    // this.invoiceList = this.$props.invoicemsg[0]
    // emitter.emit('invoiceMsg', this.iscut)
  },
  props: ['invoicemsg'],
  setup (props, ctx) {
    const commitInvoice = () => {

    }
    commitInvoice()
    return {
      showall: ref(false),
      commitInvoice
    }
  }
}
</script>

<style lang="scss">
.invoice{
  padding: 20px;
  display: flex;
  .title{
    min-width: 90px;
    font-size: 14px;
    line-height: 40px;
    color:#999999;
  }
  .invoice_show{
    // flex: 1;
    padding-top: 10px;
    // width: 260px;
    .el-descriptions{
      border: 1px solid transparent;
      border-radius: 3px;
      padding: 10px;
      transition: all 0.2s;
      .el-descriptions__body{
        background: none;
        tr{
          .el-descriptions__cell{
            display: flex;
            font-size: 14px;
            padding-bottom: 6px;
            justify-content: center;
            .el-descriptions__label{
              text-align: justify;
              text-align-last:justify;/* ie9*/
              -moz-text-align-last:justify;/*ff*/
              -webkit-text-align-last:justify;/*chrome 20+*/
              min-width: 70px;
              color: #999999;
              margin: 0;
              text-align: justify;
            }
            .el-descriptions__content{
              flex: 1;
              color: #333333;
            }
          }
        }
      }
    }
    .iscut{
        border: 1px solid #C6824E;
        background: #ffeee0;
    }
    .showallsp{
      cursor: pointer;
      text-align: right;
      margin-left: 70px;
      font-size: 14px;
      color: #999999;
    }
    .invoice_click{
      margin-top: 20px;
    }
    .show_all{
      display: block;
      margin: 20px auto;
      width: 100px;
      cursor: pointer;
    }
  }
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        .el-dialog__body{
          padding-top: 0px;
          .addfps{
            padding:0 40px;
            line-height: 50px;
            .zyitem{
              display: flex;
              align-items: center;
              .zyitem-left{
                min-width: 100px;
                display: inline-block;
                text-align-last: justify;
              }
              .zyitem-right{
                flex: 1;
              }
            }
            .contremove{
              border: 1px solid #c6824e;
              background: #ffeee0;
              display: flex;
              align-items: center;
              margin: 5px 0;
              padding: 0 20px;
              .span_show_left{
                min-width: 200px;
              }

              .span_show_center{
                margin: 0 20px;
                flex: 1;
              }
              .el-button{
                height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
